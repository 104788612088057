import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 6em;
  text-align: center;
  color: #10bdc6;

  @media only screen and (max-width: 768px) {
    font-size: 3em;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.0em;
  text-align: center;
`

export const Link = styled.a`
  front-size: 2.0em;
  text-align: center;
  color: #10bdc6;
`