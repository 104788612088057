import React from "react"
import { Router } from "@reach/router"
import { Helmet } from "react-helmet"

import Portfolio from "../pages/portfolio"
import Home from "./home"

export default function App() {
  return (
    <Router>
      <Home path="/" />
      <Portfolio path="/portfolio" />
    </Router>
  )
}
