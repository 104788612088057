import React from 'react';
import styled from 'styled-components';

import { Title, Subtitle, Link } from './typography';
import SEO from './seo';

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: fixed;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Home = () => {
    return <>
      <SEO />
      <Wrapper>
          <Content>
              <Title>Jana Lang</Title>
              <Subtitle>
                Kamera • Schnitt • Motion Graphics
              </Subtitle>
              <Link href="/portfolio">Portfolio</Link>
              <Link href="mailto:mail@janalang.de">Kontakt</Link>
          </Content>
      </Wrapper>
    </>
}

export default Home;